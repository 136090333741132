import Swal from 'sweetalert2';
import './bootstrap';
import Alpine from 'alpinejs'

window.Swal = Swal;
window.Alpine = Alpine

window.deleteConfirm = function(formId)
{
    Swal.fire({
        icon: 'warning',
        text: 'Are you sure?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#e3342f',
    }).then((result) => {
        if (result.isConfirmed) {
            document.getElementById(formId).submit();
        }
    });
}

window.cancelConfirm = function(formId)
{
    Swal.fire({
        icon: 'warning',
        text: 'Are you sure?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#e3342f',
    }).then((result) => {
        if (result.isConfirmed) {
            document.getElementById(formId).submit();
        }
    });
}


Alpine.start()

$(function () {

    // $('#send-referral').click( function() {
    //     $('#newDirectReferralModal').modal('show');
    // });

    $('#newDirectReferralModal').on('shown.bs.modal', function(event) {
        var user = $(event.relatedTarget);
        var userId = user.data("user-id");
        var userName = user.data("user-name");
        $('#to_user_name').val(userName);
        $('#to_user_id').val(userId);
        $('#lead_name').focus();
    });

    $('#sendMessageModal').on('shown.bs.modal', function(event) {
        var link = $(event.relatedTarget);
        var userId = link.data("user-id");
        console.log(userId);
        $('#recipients').val(userId);
        $('#message').focus();
    });
});

